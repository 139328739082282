<template>
  <div class="indexbox">
    <div class="bread">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/setting' }">Setting</el-breadcrumb-item>
        <el-breadcrumb-item>Log List</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="modify">
      <div class="left">
        <el-input v-model="keywords" class="inp" clearable placeholder="Type Text To Search" size="small" style="width: 200px" @change="pagechage(1)"></el-input>
        <el-button circle type="primary" @click="pagechage(1)">Search</el-button>
      </div>
      <div class="right">
        <div class="block">
          <el-date-picker v-model="value1" :clearable="false" end-placeholder="End Date" range-separator="-" start-placeholder="Start Date" style="width:220px" type="daterange">
          </el-date-picker>
          <i class="el-icon-arrow-down" style="color:#C0C4CC"></i>
        </div>
        <el-select v-model="values" clearable placeholder="All Users" @change="pagechage(1)">
          <el-option v-for="item in options" :key="item.member_id" :label="item.email" :value="item.member_id">
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="tablebox">
      <div v-loading="loading" class="draggable">
        <el-table :data="tableData" stripe>
          <el-table-column v-for="(item, index) in oldList" :key="`col_${index}`" :align="item.align" :fixed="item.fixed" :index="index" :label="item.label" :min-width="item.width"
                           :prop="newList[index].prop" :sortable="item.sortable" show-overflow-tooltip>
          </el-table-column>
        </el-table>
      </div>
      <el-pagination v-show="total>0" :current-page.sync="page" :page-size="10" :total="total" background layout="total,prev, pager, next" @current-change='pagechage'/>
    </div>
  </div>
</template>
<script>
import Sortable from "sortablejs";
import {mapGetters} from "vuex";

export default {
  computed: {
    ...mapGetters(["api_id"])
  },
  data() {
    return {
      total: 0,
      page: 1,
      tableData: [],
      tableItems: [
        {
          label: "Operator",
          prop: "email",
          width: "180",
          align: "center"
        },
        {
          label: "Operation time",
          prop: "create_time",
          width: "200",
          align: "center",
          sortable: true
        },
        {
          label: "Pedagogical operation",
          prop: "remark",
          width: "220",
          align: "center"
        }
      ],
      oldList: [],
      newList: [],
      loading: false,
      keywords: "",
      value1: "",
      options: [],
      values: "",
    };
  },
  methods: {
    getlist() {
      this.loading = true;
      this.$post({
        url: "/app/operate_log/logList",
        data: {
          page: this.page,
          member_id: this.values,
          keywords: this.keywords,
          start_time: this.value1[0],
          end_time: this.value1[1],
          api_id: this.$store.state.user.api_id
        },
        success: res => {
          this.page = res.data.current_page;
          this.tableData = res.data.data;
          this.total = res.data.total;
          setTimeout(() => {
            this.loading = false;
          }, 200);
        },
        tip: () => {
          this.loading = false
        }
      });

    },
    get_userList() {
      this.$post({
        url: "/app/api_user/allList",
        data: {
          api_id: this.$store.state.user.api_id
        },
        success: res => {
          this.options = res.data
          res.data.map(item => {
            this.options.push({
              value: item.member_id,
              label: item.email
            });
          });
        },
        tip: () => {
          this.loading = false;
        }
      });
    },
    pagechage(page) {
      this.page = page
      this.tableData = []
      this.getlist()
    },
    // 列拖拽
    columnDrop() {
      const wrapperTr = document.querySelector(
          ".draggable .el-table__header-wrapper tr"
      );
      this.sortable = Sortable.create(wrapperTr, {
        animation: 180,
        delay: 0,
        onEnd: evt => {
          const oldItem = this.newList[evt.oldIndex];
          this.newList.splice(evt.oldIndex, 1);
          this.newList.splice(evt.newIndex, 0, oldItem);
        }
      });
    }
  },
  mounted() {
    this.oldList = JSON.parse(JSON.stringify(this.tableItems));
    this.newList = JSON.parse(JSON.stringify(this.tableItems));
    this.columnDrop();
  },
  created() {
    this.get_userList();
    this.getlist();
  }
};
</script>
<style lang="scss" scoped>
.indexbox {
  width: 100%;
  height: 100%;

  /deep/ .modify {
    padding: 10px 20px;
    background: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .inp {
      margin-right: 20px;
    }

    .el-button.is-circle {
      border-radius: 10px;
    }

    .right {
      display: flex;

      .el-date-editor .el-range-separator {
        padding: 0;
        color: #c0c4cc;
      }

      .block {
        margin-right: 20px;
        position: relative;
      }

      .el-icon-arrow-down {
        position: absolute;
        top: 10px;
        right: 10px;
        pointer-events: none;
      }
    }
  }

  .bread {
    background-color: #ffffff;
    padding: 20px 20px;
    // border-bottom: red;
    border-bottom: 1px solid #f0f2f4;
  }

  .tablebox {
    width: 100%;
    padding: 20px;

    // border: 1px solid red;
    .draggable {
      border-radius: 10px;
      overflow: hidden;
      // border: 1px solid red;
    }
  }

  .formbox {
    /deep/ .el-form-item {
      margin-bottom: 30px !important;

      .el-form-item__content {
        width: 100%;

        .el-button {
          width: 100%;
          height: 48px;
          background: $color;
          border-radius: 5px;
          border-color: $color;
        }
      }
    }
  }
}
</style>
